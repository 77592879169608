import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { routes } from './app/app.routes';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { AngularFireAnalyticsModule, ScreenTrackingService, UserTrackingService } from '@angular/fire/compat/analytics';
import { getAnalytics } from "firebase/analytics";

import { IonicStorageModule } from '@ionic/storage-angular';
import { Firestore, connectFirestoreEmulator, initializeFirestore } from 'firebase/firestore';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    importProvidersFrom(IonicModule.forRoot({})),
    provideRouter(routes),
    importProvidersFrom(
      provideFirebaseApp(() => {
        const app = initializeApp(environment.firebaseConfig)
        getAnalytics(app);
        return app
      })
    ),
    importProvidersFrom(provideFirestore(() => {
      const app = initializeApp(environment.firebaseConfig)
      let firestore: Firestore;
      if (environment.useEmulators) {
        firestore = initializeFirestore(app, {});
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      } else {
        firestore = getFirestore();
      }
      return firestore
    })
    ),
    importProvidersFrom(IonicStorageModule.forRoot()),
    ScreenTrackingService,
    UserTrackingService,
    AngularFireAnalyticsModule
  ],
});
