// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useEmulators: false,
  firebaseConfig: {

    //authDomain: "cnice-cine-test.firebaseapp.com",
    authDomain: 'test.cine.cnice.fr',
    projectId: "cnice-cine-test",
    apiKey: "AIzaSyAoHA0lPYKVMOs2eT26VwPt5HvU526QEcI",
    storageBucket: "cnice-cine-test.appspot.com",
    messagingSenderId: "817885656461",
    appId: "1:817885656461:web:a4dcf23e3c5870056979c4",
    measurementId: "G-39TGEJH3S3"
  },
  firebaseStorageURL: "https://firebasestorage.googleapis.com/v0/b/cnice-cine-test.appspot.com/o/affiches%2F"
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
